<template>
    <div class="signup container">
        <form @submit.prevent class="card-panel">
            <h2 class="center deep-purple-text">Sign Up with Us</h2>
            <div class="field">
                <label for="email">Email:</label>
                <input type="email" name="email" id="email" v-model="email">
            </div>
            <div class="field">
                <label for="password">Password:</label>
                <input type="password" name="password" id="password" v-model="password">
            </div>
            <div class="field">
                <label for="alias">Alias:</label>
                <input type="text" name="alias" id="alias" v-model="alias">
            </div>
            <p v-if="feedback" class="red-text center">{{ feedback }}</p>
            <div class="field center">
                <button @click="Signup()" class="btn deep-purple">Signup</button>
            </div>
        </form>
    </div>
</template>

<script>
import slugify from 'slugify';
import firebase from 'firebase';
import db from '@/firebase/init'

export default {
    name: 'Signup',
    data() {
        return {
            email: null,
            password: null,
            alias: null,
            feedback: null,
            slug: null
        }
    },
    methods: {
        Signup() {
            if(this.alias && this.email && this.password) {
                this.slug = slugify(this.alias, {
                    replacement: '-',
                    remove: /[$*_+~.()'"!\-:@]/g,
                    lower: true
                })                
                let checkAlias = firebase.functions().httpsCallable('checkAlias');
                checkAlias({ slug: this.slug }).then(result => {                    
                    if(!result.data.unique) {
                        this.feedback = 'This alias is unavailable. Please choose another alias.';
                    } else {
                        firebase.auth().createUserWithEmailAndPassword(this.email, this.password)
                        .then(cred => {
                            db.collection('users').doc(this.slug).set({
                                alias: this.alias,
                                geolocation: null,
                                user_id: cred.user.uid
                            })
                        }).then(() => {
                            this.$router.push({name: 'GMap'});
                        })
                        .catch(error => {
                            this.feedback = error.message;
                        })
                        this.feedback = 'This alias is available';
                    }
                })
            } else {
                this.feedback = "You must enter all fields."
            }
        }
    },
}
</script>

<style>
.signup {
    max-width: 400px;
    margin-top: 60px;
}

.signup h2 {
    font-size: 2.4em;
}

.signup .field {
    margin-bottom: 16px;
}
    
</style>
<template>
    <div class="navbar">
        <nav class="deep-purple darken-1">
            <div class="container">
                <router-link :to="{name: 'GMap'}" class="brand-logo left">Geo Ninjas!</router-link>
                <ul class="right">
                    <li v-if="!user"><router-link :to="{name: 'signup'}">Sign Up</router-link></li>
                    <li v-if="!user"><router-link :to="{name: 'login'}">Login</router-link></li>
                    <li v-if="user"><a>{{ user.email }}</a></li>
                    <li v-if="user"><a @click="Logout()" >Logout</a></li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
import firebase from 'firebase';

export default {
    name: 'Navbar',
    data() {
        return {
            user: null
        }
    },
    methods: {
        Logout() {
            firebase.auth().signOut().then(() => {
                this.$router.push({name: 'login'})
            });
        }
    },
    created() {
        firebase.auth().onAuthStateChanged(user => {
            this.user = user;
        })
    }
}
</script>

<style  scoped>

</style>
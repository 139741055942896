import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as VueGoogleMaps from 'vue2-google-maps'
import firebase from 'firebase'

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyC32Ff3D488EZIPaF_0e04jtdyb3FJTTx8',
      libraries: 'places', 
    }
  }
)

let app = null;

// wait for firebase auth to init before creating the app
firebase.auth().onAuthStateChanged(() => {

  // init app if not already created
  if(!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
  }  
  
});
import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyBSpi3YKLHsV7iH6VbNBzU8mHevLDmEKIU",
    authDomain: "geo-ninjas-af864.firebaseapp.com",
    projectId: "geo-ninjas-af864",
    storageBucket: "geo-ninjas-af864.appspot.com",
    messagingSenderId: "727339430145",
    appId: "1:727339430145:web:f1b50414c7298f508a6b00"
  };
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.firestore().settings({timeStampsinSnapshots: true});

export default firebaseApp.firestore();
<template>
    <div class="map">
        <GmapMap
            class="google-map"
            :center="{lat: lat, lng: lng}"
            :zoom="13"
            map-type-id="terrain"
            :options="{
                streetViewControl: false,
            }"
            ref="gMapRef"
        />
    </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/firebase/init';

export default {
    name: 'GMap',
    data() {
        return {
            lat: 4.2,
            lng: 101,
            map: undefined
        }
    },
    methods: {
        RenderMap() {
            db.collection('users').get()
            .then(users => {
                users.docs.forEach(doc => {
                    let data = doc.data();
                    if(data.geolocation) {
                        let marker = new window.google.maps.Marker({
                            position: {
                                lat: data.geolocation.lat,
                                lng: data.geolocation.lng
                            },
                            map: this.map,
                        });
                        marker.addListener('click', () => {
                            this.$router.push({name: 'viewProfile', params: {id: doc.id}})
                        })
                    }
                })
            })            
        }
    },
    mounted() {
        this.$refs.gMapRef.$mapPromise.then((map) => {
            this.map = map;            
        });        

        // get current user
        let user = firebase.auth().currentUser
        
        // get user geolocation
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => {
                this.lat = pos.coords.latitude;
                this.lng = pos.coords.longitude;

                // find the user record and then update geocoords
                db.collection('users').where('user_id', '==', user.uid).get()
                .then(snapshot => {
                    snapshot.forEach((doc) => {
                        db.collection('users').doc(doc.id).update({
                            geolocation: {
                                lat: pos.coords.latitude,
                                lng: pos.coords.longitude
                            }
                        })
                    })
                }).then(() => {
                    this.RenderMap();
                });
            }, (err) => {
                // this.RenderMap();
            }, {
                maximumAge: 60000,
                timeout: 3000
            })
        }
    }
}
</script>

<style scoped>
.google-map {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
</style>